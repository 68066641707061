<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content max_small_box">
                <div className="mapBox" >
                    <div className="address" id="address"></div>
                </div>
                <p class="gotomap" @click="handlegoToMap">点击导航</p>
                <!-- <div class="first">
                    <p>工作时间：周一 ~ 周五  9：00 - 17：00   </p> 
                    <p>如电话未接通或拨通无人接听，请稍后重新拨打。</p>
                </div> -->
                <div class="second">
                    <p class="title">联系我们：</p>
                    <div class="text">
                        <div class="list" v-for="(item, index) in detailInfo" :key="index">
                            <p>{{item.title}}：{{item.intro}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ContactTop from '../../components/contact/ContactTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//地图逻辑
const MapEffect = () => {
    const map = () => {
        let sContent ="地址：东莞市松山湖科技产业园区工业南路2号";
        let map = new BMap.Map("address"); // 创建Map实例
        // 创建地图实例
        let point = new BMap.Point(113.889991,22.953078);
        // 创建点坐标
        map.centerAndZoom(point, 17);
        // 初始化地图， 设置中心点坐标和地图级别
        let marker = new BMap.Marker(point);
        let infoWindow = new BMap.InfoWindow(sContent);  // 创建信息窗口对象 
        map.openInfoWindow(infoWindow,point); //开启信息窗口
        map.addOverlay(marker);
        map.enableScrollWheelZoom(true);
    }

    const handlegoToMap = () => {
        var lng=113.889991
        var lat=22.953078
        var u = navigator.userAgent, app = navigator.appVersion;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        // "http://api.map.baidu.com/marker?location="+lat+","+lng+"&title=东莞市松山湖科技产业园区工业南路2号&output=html"
        if (isAndroid) {
            var d = new Date();
            var t0 = d.getTime();
            window.location.href="androidamap://viewMap?sourceApplication=appname&poiname=东莞市松山湖科技产业园区工业南路2号&lat="+lat+"&lon="+lng+"&dev=0";
            //判断是否跳转
            setTimeout(function(){
                let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
                if(typeof hidden =="undefined" || hidden ==false){
                    //调用高德地图
                    window.location.href ="https://uri.amap.com/marker?position="+lng+","+lat+"&name=东莞市松山湖科技产业园区工业南路2号";
                }
            }, 1);
        }
        if (isIOS) {
            var d = new Date();
            var t0 = d.getTime();
            window.location.href="iosamap://viewMap?sourceApplication=appname&poiname=东莞市松山湖科技产业园区工业南路2号&lat="+lat+"&lon="+lng+"&dev=0";
            //判断是否跳转
            setTimeout(function(){
                let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
                if(typeof hidden =="undefined" || hidden ==false){
                    //调用高德地图
                    window.location.href ="https://uri.amap.com/marker?position="+lng+","+lat+"&name=东莞市松山湖科技产业园区工业南路2号";
                }
            }, 1);
        }
    }
    
    return { map, handlegoToMap }
}

//列表处理函数
const detailEffect = (route) => {

    //列表
    const detailInfo = ref()

    //获取详情
    const detail = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
        }
        await post('/contact/get_contact', postData).then((res) => {
            // console.log('联系信息', res)
            detailInfo.value = res.data.content
        })
    }

    return { detailInfo, detail }
}


export default {
    name: 'ContactUs',
    components: { Header, Footer, FloatingFrame, ContactTop },
    setup() {
        const  route = useRoute()

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //地图逻辑
        const { map, handlegoToMap } = MapEffect()

        const { detailInfo, detail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
            map()
        });

        detail(route.params.id, route.params.page_id)

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detailInfo, handlegoToMap }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 50px;
}
.content{
    display: flex;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
        display: block;
    }
   .mapBox{
       width: 50%;
       height: 300px;
       @media screen and (max-width: 1200px){
            width: 100%;
        }
       .address{
           width: 100%;
           height: 100%;
       }
   }
   .gotomap{
       font-size: 14px;
       margin-top: 15px;
       text-align: center;
       text-decoration: underline;
       display: none;
       @media screen and (max-width: 900px){
            display: block;
        }
   }
   .first{
       margin-top: 50px;
       padding-bottom: 50px;
       border-bottom: 1px solid #bbb;
       p{
           color: rgba(16, 16, 16, 100);
            font-size: 16px;
            margin-bottom: 15px;
            text-align: center;
       }
   }
   .second{
       /* margin-top: 50px; */
       width: 50%;
       @media screen and (max-width: 1200px){
            width: 100%;
            margin-top: 30px;
        }
       .title{
           color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: $all-font-weight;
            text-align: center;
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
       }
       .text{
           margin-top: 30px;
           .list{
                width: 90%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;
                @media screen and (max-width: 1200px){
                    display: block;
                }
                p{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    width: 100%;
                    text-align: center;
                    @media screen and (max-width: 1200px){
                        margin-bottom: 30px;
                        text-align: center;
                        line-height: 20px;
                    }
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
           }
       }
   }
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>